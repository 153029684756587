<template>
  <Content>
    <template #contentTitle>
      {{ $t("warehouse.application_list") }}
    </template>
    <template v-slot:contentBody="wrap">
      <a-row type="flex"
             justify="space-between">
        <a-col>
          <a-form layout="inline">
            <a-form-item class="mb-2">
              <a-input v-model:value="data.searchKey"
                       :placeholder="$t('warehouse.please_input_apply_name_or_id')"
                       allowClear
                       style="width: 250px" />
            </a-form-item>
            <a-form-item class="mb-2">
              <a-button type="primary"
                        :loading="data.loading"
                        @click="handleSearch">{{ $t('common.query') }}</a-button>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>

      <div class=" mt-2">
        <a-table :columns="columns"
                 size="small"
                 :data-source="data.list"
                 :scroll="{ x: 400, y: wrap.contentHeight - 150 }"
                 :pagination="false"
                 :rowKey="(record) => record.appId"
                 :loading="data.loading">

          <template v-slot:authorizeInfos="{ record }">
            {{getAuthorizeInfos(record)}}
          </template>

          <template v-slot:authorizeTime="{ record }">
            {{$filters.utcToCurrentTime(record.authorizeTime)}}
          </template>

          <template v-slot:operate="{ record }">
            <a-popconfirm :title="$t('common.are_you_sure')"
                          :ok-text="$t('common.confirm')"
                          :cancel-text="$t('common.cancel')"
                          @confirm="hanldeCancelAuthorization (record)">
              <a-button type="ghost" danger>
                {{$t('warehouse.cancel_application')}}
              </a-button>
            </a-popconfirm>
          </template>
        </a-table>
      </div>

    </template>

  </Content>
</template>

<script>
import { reactive, onMounted, } from "vue";
import {
  Row, Col, Table,
  Button, Form, Input,
  Dropdown, Popconfirm,
  Menu,
  Modal
} from "ant-design-vue";
import Content from "../components/Content.vue";
import CPager from "../components/CPager.vue";
import { getList, cancelAuthorization } from "../../api/modules/authorizationManage/list";
import { useI18n } from "vue-i18n/index";

export default {
  name: "open_platform_authorizes",
  components: {
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AForm: Form,
    AButton: Button,
    AFormItem: Form.Item,
    AInput: Input,
    Content,
    CPager,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    AModal: Modal,
    ATextarea: Input.TextArea,
    APopconfirm: Popconfirm,

  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const columns = [
      {
        title: () => vueI18n.t('warehouse.apply_id'),
        dataIndex: "appId",
      },
      {
        title: () => vueI18n.t('warehouse.license_application'),
        dataIndex: "appName",
      },
      {
        title: () => vueI18n.t('warehouse.permission'),
        dataIndex: "authorizeInfos",
        slots: {
          customRender: "authorizeInfos",
        },
      },
      {
        title: () => vueI18n.t('warehouse.privileged_time'),
        dataIndex: "authorizeTime",
        slots: {
          customRender: "authorizeTime",
        },
      },
      {
        dataIndex: "operate",
        slots: {
          customRender: "operate",
        },
      },
    ];

    const data = reactive({
      loading: false,
      searchKey: null,
      list: [],
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10,
      },
    });


    const handleSearch = () => {
      funcGetList();
    }

    const funcGetList = () => {
      data.loading = true;
      getList(data.searchKey)
        .then((res) => {
          if (res.result) {
            data.list = res.result;
          } else {
            data.list = [];
          }
        })
        .finally(() => {
          data.loading = false;
        });
    }


    const hanldeCancelAuthorization = (record) => {
      cancelAuthorization(record.authorizeApplicationId).then(() => {
        funcGetList();
      }).catch(() => {

      })
    }

    const getAuthorizeInfos = (record) => {
      let infos = [];
      record.authorizeInfos.forEach(x =>
        infos.push(x.authorizeInfoName)
      );
      return infos.join(',');
    }

    onMounted(() => {
      handleSearch()
    });

    return {
      columns,
      data,
      handleSearch,
      hanldeCancelAuthorization,
      getAuthorizeInfos
    };
  },
};
</script>

<style lang="less" scoped>
</style>