import client from "../../client";

const Api = {
  getList: "/api/OpenPlatformService/ShopAuthorizeApp/GetShopAuthorizeAppList",
  cancelAuthorization : "/api/OpenPlatformService/ShopAuthorizeApp/CancelAppAuthShop",
};

export const getList = (searchKey) => {
  return client.request({
    url: Api.getList,
    data: {searchKey},
    method: "post",
  });
};


export const cancelAuthorization  = (authorizeApplicationId) => {
  return client.request({
    url: Api.cancelAuthorization ,
    data: {authorizeApplicationId},
    method: "post",
  });
};

